import React from 'react'
import './experience.css'

const Experience = () => {
	return (

		<section id="experience">
			<div className="exp-left">
				<h3>Flexibles pour tous genres de projets</h3>
				<h2 class="art" data-text="Art">Artistiques</h2>
				<h3>Des solutions créatives sur mesure</h3>
				<div id="button-4">
					<a href="/Contactpage" className='btn btn-exp'>Demander un devis</a>
					<div id="underline"></div>
				</div>
			</div>
			<div className="exp-right">
				<h3>Maîtres Wordpress pour la création de</h3>
				<h2 class="glitch" data-text="Sites Web">Sites Web</h2>
				<h3>uniques</h3>
				<div id="button-4">
					<a href="/Contactpage" className='btn btn-exp2'>Je veux un site Web!</a>
					<div id="underline"></div>
				</div>
			</div>
		</section>

	)
}

export default Experience