import React, { useState, useEffect } from 'react';
import './expertise.css';
import NavBavards from '../lesbavards/NavBavards'
import Footer from '../../components/footer/Footer'
import Typesprojets from '../../assets/typesprojets.webp'
import Typesprojets2 from '../../assets/typesprojets2.webp'
import { Link } from 'react-router-dom';
import Eyes from '../../assets/eyes.webp'
import Partenaire from '../../assets/partenaire.webp'
import Expertiseimg from '../../assets/expertise.webp'
import Expertiseimg2 from '../../assets/expertiseimg2.webp'
import blogPosts from '../lesbavards/blogPosts';

const Expertise = () => {
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    const lastBlogPost = blogPosts[0];
    useEffect(() => {
        if (window.location.pathname === '/expertise') {
            setBgClass('expertise-background');
        } else {
            setBgClass('');
        }
    }, []);
    const [bgClass, setBgClass] = useState('');
    return (

        <div id="top" className='expertise-page'>
            <div className="content__hider"></div>
            <NavBavards />
            <div className={`bavards-wrapper ${bgClass}`}>
                <div className="expertise-wrapper">
                    <img src={Expertiseimg2} className="expertiseimg2" alt="Illustration d'une icône de globe"/>
                    <div className="ariane"><h4>Expertise</h4></div>
                    <h2>On fait de la conception web et graphique</h2>
                    <div className="expertise-description">
                        <div className="expertise-hero">
                            <img src={Expertiseimg} alt="Image d'une personne à l'ordinateur"/>
                            <p>Notre approche de la conception de sites web inclut la stratégie, le design, le développement et le référencement naturel (SEO), ainsi que des services d'entretien et de conception graphique. Nous sommes déterminés à gérer chaque étape de votre projet de site web avec soin et à nous assurer qu'il réponde à vos besoins en constante évolution. Bien que nous ne soyons pas en mesure de gérer votre prochaine campagne de publicité ou de gérer vos réseaux sociaux, nous nous concentrons sur l'excellence dans la conception de sites web et la conception graphique plutôt que sur la quantité de services que nous offrons.</p>
                        </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="ariane"><h4>Types de projets</h4></div>
                    <div className="expertise-description">
                        <div className="img-expertise">
                            <img src={Eyes} className="eyes" alt="Illustration du mot OMG" />
                            <img src={Typesprojets} alt="Image d'un ordinateur" />
                        </div>
                        <div className="text-expertise">
                            <h2>On peut concevoir pas mal tout ce que vous imaginez</h2>
                            <p>Nous sommes prêts à relever tous les défis en matière de développement web et de conception graphique ! Que ce soit pour la création d'une simple page web ou d'un site web complet, nous sommes là pour vous accompagner dans votre projet. Nous proposons des solutions sur mesure pour répondre à vos besoins et nous sommes là pour vous aider à chaque étape, que ce soit pour de la stratégie ou du développement.</p><br />
                            <p>En tant qu'agence créative, nous attachons une grande importance à l'aspect visuel de votre site web. Nous travaillons avec WordPress pour la plupart de nos projets, car cela nous permet de proposer des designs modernes et esthétiques tout en garantissant une expérience utilisateur optimale. Cependant, nous sommes toujours à la recherche des dernières tendances et technologies pour être en mesure de vous offrir des designs innovants et originaux.</p>
                            <img src={Typesprojets2} alt="Image de plusieurs ordinateurs"/>
                        </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="ariane"><h4>Démarche</h4></div>
                    <h2 class="confiance">La confiance est la fondation de toute collaboration réussie.</h2>
                    <div className="expertise-approche">
                        <div className="approche-text">
                            <img src={Partenaire} className="partenaire" alt="Illustration de deux bonhommes sourires rapprochés"/>
                            <p>Le web peut être complexe, mais chez Swayed, on s'engage à ne jamais utiliser des termes techniques compliqués pour nos clients. Au lieu de cela, nous visons à éduquer nos clients en partageant notre expertise et en expliquant clairement les raisons derrière nos décisions. Nous avons confiance en vous, et vous pouvez avoir confiance en nous pour travailler ensemble afin de livrer le meilleur projet possible.</p> <br />
                            <p>Après tout, chaque projet est une collaboration. Chez Swayed, nous ne nous contentons pas de prendre votre chèque et de revenir quelques mois plus tard avec un site web. Nous considérons votre projet comme un investissement en temps et en argent, et nous travaillerons en étroite collaboration avec vous jusqu'à ce que nous obtenions un produit qui répondra à vos objectifs et tiendra compte de vos enjeux. Que ce soit lors de rencontres hebdomadaires, d'ateliers collaboratifs, de présentations ou de formations, nous serons là pour discuter et avancer ensemble si vous choisissez de travailler avec nous.</p>
                        </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="ariane"><h4>Workflow</h4></div>
                    <h2 className='workflow-title'>Un projet chez Swayed, ça se passe comment?</h2>
                    <div className="workflow-step">
                        <div className="workflow-step-number">
                            <h3> 01 </h3>
                        </div>
                        <div className="workflow-step-description">
                            <h2>Écoute et compréhension des exigences</h2>
                            <p>Nous commençons par comprendre les besoins du client en termes de projet créatif, qu'il s'agisse de la création d'un site web, d'un <i>packaging</i> de produit, d'une identité visuelle ou de tout autre projet créatif. Nous posons les bonnes questions et écoutons attentivement les réponses du client pour bien cerner ses besoins.</p>
                        </div>
                    </div>
                    <div className="line-break2"></div>
                    <div className="workflow-step">
                        <div className="workflow-step-number">
                            <h3> 02 </h3>
                        </div>
                        <div className="workflow-step-description">
                            <h2>Stratégie créative</h2>
                            <p>Une fois que les besoins du client sont bien compris, nous élaborons une stratégie créative pour répondre à ces besoins. Cette stratégie prend en compte les objectifs du client, le public cible, les contraintes de temps et de budget, ainsi que les tendances actuelles dans le domaine créatif.</p>
                        </div>
                    </div>
                    <div className="line-break2"></div>
                    <div className="workflow-step">
                        <div className="workflow-step-number">
                            <h3> 03 </h3>
                        </div>
                        <div className="workflow-step-description">
                            <h2>Conception</h2>
                            <p>Une fois que la stratégie créative est en place, nous passons à la conception des différents éléments du projet créatif. Cela peut inclure la création de maquettes de design, la conception de supports de communication, de présentations visuelles, la conception de logos et de chartes graphiques et plusieurs autres.</p>
                        </div>
                    </div>
                    <div className="line-break2"></div>
                    <div className="workflow-step">
                        <div className="workflow-step-number">
                            <h3> 04 </h3>
                        </div>
                        <div className="workflow-step-description">
                            <h2>Révision et approbation</h2>
                            <p>Une fois que les éléments créatifs ont été développés, ils sont présentés au client pour révision et approbation. Nous sommes prêts à apporter des modifications en fonction des commentaires du client et travaillons en étroite collaboration avec lui pour obtenir le résultat final souhaité.</p>
                        </div>
                    </div>
                    <div className="line-break2"></div>
                    <div className="workflow-step">
                        <div className="workflow-step-number">
                            <h3> 05 </h3>
                        </div>
                        <div className="workflow-step-description">
                            <h2>Production</h2>
                            <p>Une fois que les éléments créatifs ont été approuvés, nous passons à la production. Cela peut inclure la programmation de sites web, la création de contenus graphiques, la création et la traduction de contenu ou tout autre service nécessaire à la production finale.</p>
                        </div>
                    </div>
                    <div className="line-break2"></div>
                    <div className="workflow-step">
                        <div className="workflow-step-number">
                            <h3> 06 </h3>
                        </div>
                        <div className="workflow-step-description">
                            <h2>Révision finale</h2>
                            <p>Une fois que le produit final a été produit, nous procédons à une révision finale pour nous assurer que tout est conforme aux spécifications du client. Dans le cas d'un site Web, la révision finale est également le moment où nous effectuons l'optimisation pour les moteurs de recherche (SEO), en nous assurant que les éléments tels que les balises de titre, les balises méta descriptions, les mots clés et les liens internes sont optimisés pour aider le site à être mieux référencé dans les résultats de recherche.</p>
                        </div>
                    </div>
                    <div className="line-break2"></div>
                    <div className="workflow-step">
                        <div className="workflow-step-number">
                            <h3> 07 </h3>
                        </div>
                        <div className="workflow-step-description">
                            <h2>Livraison et lancement</h2>
                            <p>Une fois que la révision finale est terminée, le produit final est livré au client et peut être lancé auprès du public. Nous restons à l'écoute du client pour éventuellement apporter des modifications ou des ajustements nécessaires.</p>
                        </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="ariane"><h4>Contact</h4></div>
                    <div className="expertise-contact">
                        <h2>Venez nous jaser!</h2>
                        <div class="container-btn">
                            <Link to="/Contactpage" className="expertise-btn" onClick={scrollToTop}>Nous joindre</Link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer lastBlogPost={lastBlogPost} />
        </div>
    );
}

export default Expertise;