import React, { useRef, useEffect } from 'react';
import './header.css';
import './../../index.css';
import CTA from './CTA';
import HeaderSocials from './HeaderSocials';
import Wow from '../../assets/wow.webp';

const Header = () => {
  const wowContainerRef = useRef(null);
  const wowRef = useRef(null);

  const handleMouseMove = (e) => {
    const wow = wowRef.current;
    if (wow) {
      const { clientX, clientY } = e;
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const deltaX = clientX - centerX;
      const deltaY = clientY - centerY;
      wow.style.transform = `translate(${deltaX * -0.05}px, ${deltaY * -0.05}px)`;
    }
  };

  const handleMouseLeave = () => {
    const wow = wowRef.current;
    if (wow) {
      wow.style.transform = 'translate(0, 0)';
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <header>
      <div className="container header__container">
        <div className="logo__wrapper">
          <div className="logo__container">
             {/* <div className="wow-container" ref={wowContainerRef}>
              <img src={Wow} className="wow" alt="wow" ref={wowRef} onMouseLeave={handleMouseLeave} />
            </div> */}
            <span>Swayed</span>
          </div>
          <h1 className="reveal-text">
            <pre>agence créative &nbsp;</pre><span></span>
          </h1>
          <p>(à Chicoutimi)</p>
        </div>
        <CTA />
        <HeaderSocials />
        <a href="#about" className='scroll__down'>descends →</a>
      </div>
    </header>
  );
};

export default Header;
