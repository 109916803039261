import React from 'react'
import NavBavards from '../lesbavards/NavBavards'
import Footer from '../../components/footer/Footer'
import './vols.css'
import VOL3 from '../../assets/bavards/vol3.webp'
import { useNavigate } from 'react-router-dom';
import blogPosts from '../lesbavards/blogPosts';

const Vol3 = () => {
    const navigate = useNavigate();
    const lastBlogPost = blogPosts[0];

    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className="vols-tweaks">
            <NavBavards />
            <div className="content__hider"></div>
            <div className="bavards-wrapper">
                <div className="bavards__container vol-wrapper">
                    <button onClick={goBack}>← retour en arrière</button>
                    <img className='vol__img' src={VOL3} alt="Illustration de crânes avec fleurs qui éclosent" />
                    <div className="title__vol">raviver la flamme en renouant avec l'imagination de votre enfance</div>
                    <div className="author-date__vol">
                        <span className="date">16 mars 2023</span>
                        <span>|</span>
                        <span className="author"> Vincent Royer</span>
                    </div>
                    <span className="volume">Volume 3</span>
                    <div className="vol__article">
                        <p>L'imagination est une force motrice puissante qui peut vous aider à exceller dans tous les aspects de votre vie, que ce soit en créant des œuvres d'art, en résolvant des problèmes complexes ou en innovant dans votre travail. Cependant, avec le temps, il est facile de perdre cette imagination spontanée et créative que nous avions tous dans notre enfance. Nous devenons souvent plus rationnels et axés sur les résultats à mesure que nous grandissons, et cela peut entraîner une diminution de notre capacité à penser de manière imaginative.</p>
                        <br></br><p>Heureusement, il existe des moyens de raviver la flamme de votre imagination, en renouant avec l'innocence de votre enfance et en laissant libre cours à votre créativité. Voici quelques stratégies clés pour stimuler votre imagination et développer votre potentiel créatif.</p>
                        <h3>Explorer de nouveaux horizons</h3>
                        <p>Pour enrichir votre imagination, il est essentiel de sortir de votre zone de confort et d'explorer de nouveaux horizons. Que ce soit en voyageant, en visitant des musées ou des expositions, en assistant à des spectacles ou en lisant des livres sur des sujets inconnus, l'exploration de nouvelles expériences peut vous aider à stimuler votre créativité. En effet, ces expériences peuvent vous amener à découvrir de nouvelles idées, de nouveaux points de vue, de nouveaux défis, et vous donneront l'occasion de vous inspirer de nouvelles sources d'inspiration.</p>
                        <h3>Pratiquer la réflexion</h3>
                        <p>Pour stimuler votre imagination, il est également important de pratiquer la réflexion. Prenez le temps de vous poser des questions, de réfléchir sur des sujets qui vous passionnent et de chercher des solutions créatives à des problèmes complexes. La réflexion peut vous aider à découvrir des idées novatrices et à développer votre pensée critique. Par exemple, prenez l'habitude de prendre des notes sur vos idées et vos pensées, de les revisiter régulièrement, de les enrichir avec des nouvelles perspectives et de les intégrer dans votre travail.</p>
                        <h3>Dialoguer et collaborer avec d'autres professionnels</h3>
                        <p>Le dialogue et la collaboration avec d'autres professionnels peuvent également aider à stimuler votre imagination. Échangez avec des collègues ayant des perspectives différentes de la vôtre, participez à des projets collaboratifs ou intégrez des groupes de réflexion pour découvrir de nouvelles idées et des solutions créatives. Ces interactions peuvent vous aider à sortir de votre zone de confort, à vous inspirer des nouvelles tendances, et à collaborer pour développer des idées innovantes. Par exemple, participez à des ateliers de créativité, des séances de brainstorming ou autres activités intellectuelles pour stimuler votre esprit d'équipe et votre créativité.</p>
                        <h3>Se reconnecter avec l'enfant intérieur</h3>
                        <p>L'enfant en chacun de nous possède une imagination débridée, une curiosité sans fin et un sens de l'aventure indomptable. Cependant, à mesure que nous grandissons, nous perdons souvent cette innocence et cette créativité spontanée. Pour renouer avec votre enfant intérieur et stimuler votre imagination, essayez de vous adonner à des activités que vous aimiez dans votre jeunesse, comme dessiner, écrire des histoires, jouer à des jeux créatifs ou simplement passer du temps à explorer la nature.</p>
                        <br></br><p>Les jeux créatifs tels que la construction, la fabrication de bijoux ou la sculpture sont également des moyens amusants de stimuler votre imagination et de retrouver votre enfant intérieur. Ces activités vous permettent de travailler avec vos mains et de créer quelque chose de nouveau et unique, en utilisant votre imagination pour trouver des solutions créatives à des problèmes de conception.</p><br />
                        <p>Enfin, passer du temps en pleine nature peut vous aider à retrouver votre émerveillement enfantin devant la beauté de la nature et à stimuler votre créativité. La nature offre une multitude de textures, de couleurs, de formes et de sons qui peuvent inspirer votre imagination et vous aider à trouver des idées créatives pour votre travail.</p>
                        <br /><p>Pour raviver la flamme de votre imagination et retrouver l'enfant en vous, essayez de vous reconnecter avec les activités que vous aimiez dans votre jeunesse. Ces activités peuvent vous aider à stimuler votre créativité et à vous amuser tout en laissant libre cours à votre imagination. N'oubliez pas que la créativité ne doit pas être réservée aux artistes ou aux écrivains professionnels, elle est présente en chacun de nous et il suffit simplement de laisser votre enfant intérieur s'exprimer librement. Alors, prenez le temps de vous amuser et de créer, et laissez votre imagination vous guider!</p>
                    </div>
                </div>
            </div>
            <Footer lastBlogPost={lastBlogPost} />
        </div>
    )
}

export default Vol3;