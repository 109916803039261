import React, { useState, useEffect } from 'react';
import './about.css';

const About = () => {
  const [isVisible, setIsVisible] = useState([false, false, false]);
  const [isTextVisible, setIsTextVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const h2Elements = document.querySelectorAll('.about__me h2');

      h2Elements.forEach((h2, index) => {
        const rect = h2.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          if (!isVisible[index]) {
            setTimeout(() => {
              setIsVisible((prev) => {
                const updatedArray = [...prev];
                updatedArray[index] = true;
                return updatedArray;
              });
            }, index * 200); // Adjust the delay as needed
          }
        } else {
          if (isVisible[index]) {
            setIsVisible((prev) => {
              const updatedArray = [...prev];
              updatedArray[index] = false;
              return updatedArray;
            });
          }
        }
      });

      const delayedTextElement = document.getElementById('delayedText');
      const delayedTextRect = delayedTextElement.getBoundingClientRect();
      if (
        delayedTextRect.top >= 0 &&
        delayedTextRect.bottom <= window.innerHeight
      ) {
        if (!isTextVisible) {
          setTimeout(() => {
            setIsTextVisible(true);
          }, 200); // Adjust the delay as needed
        }
      } else {
        if (isTextVisible) {
          setIsTextVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible, isTextVisible]);

  return (
    <section id="about">
      <div className="container about__container">
        <div className="about__me">
          <h2 className={isVisible[0] ? 'visible slide-in' : ''}>Votre partenaire</h2>
          <h2 className={isVisible[1] ? 'visible slide-in' : ''}>créatif pour un</h2>
          <h2 className={isVisible[2] ? 'visible slide-in' : ''}>monde en mouvement</h2>
        </div>
        <div className={`about__content ${isTextVisible ? 'visible' : ''}`}>
          <p id="delayedText" className={isTextVisible ? 'fadeIn' : 'hidden'}>
            La capacité de se démarquer et de se faire remarquer prend forme en développant une identité forte. Chez nous, l’influence de l’esthétisme sur le quotidien est une véritable fascination. Nos talents s’affairent à imaginer des concepts flamboyants, mais par-dessus tout, à votre image.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
