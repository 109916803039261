import './portfolio.css';
import IMG1 from '../../assets/IMG1.webp';
import IMG2 from '../../assets/IMG2.webp';
import IMG3 from '../../assets/IMG3.webp';
import IMG4 from '../../assets/IMG4.webp';
import IMG5 from '../../assets/IMG5.webp';
import IMG6 from '../../assets/IMG6.webp';
import IMG7 from '../../assets/IMG7.webp';
import IMG8 from '../../assets/IMG8.webp';
import IMG9 from '../../assets/IMG9.webp';
import IMG10 from '../../assets/IMG10.webp';
import IMG11 from '../../assets/IMG11.webp';
import IMG12 from '../../assets/IMG12.webp';
import IMG13 from '../../assets/IMG13.webp';
import IMG14 from '../../assets/IMG14.webp';
import IMG15 from '../../assets/IMG15.webp';
import IMG16 from '../../assets/IMG16.webp';
import IMG17 from '../../assets/IMG17.webp';
import CLIENT1 from '../../assets/client1.webp';
import CLIENT2 from '../../assets/client2.webp';
import CLIENT3 from '../../assets/client3.webp';
import CLIENT4 from '../../assets/client4.webp';
import CLIENT5 from '../../assets/client5.webp';
import CLIENT6 from '../../assets/client6.webp';
import React, { useState } from "react";
import Modal from 'react-modal';
const portfolioData = [
  { id: 1, category: 'design', title: 'La musica Electronica', image: IMG1, description: 'Stickers | Illustrator' },
  { id: 2, category: 'design', title: 'Resurgence', image: IMG2, description: 'Affiche personnalisée | Photoshop' },
  { id: 3, category: 'design', title: 'Lumina', image: IMG3, description: "Carte d'affaire + Logo | Illustrator" },
  { id: 4, category: 'design', title: 'Daillac', image: IMG4, description: "Carte d'affaire | Illustrator" },
  { id: 5, category: 'design', title: 'Leroyer&co', image: IMG5, description: 'Lookbook | Photoshop + InDesign' },
  { id: 6, category: 'design', title: 'Ember Emporium', image: IMG6, description: 'Product Design + Logo | Illustrator' },
  { id: 7, category: 'design', title: 'Rough&Refined', image: IMG7, description: 'Product Design + Logo | Illustrator + Photoshop' },
  { id: 8, category: 'design', title: 'Stealthy', image: IMG8, description: "Carte d'affaire + Logo | Illustrator" },
  { id: 9, category: 'design', title: "The Mapmaker's Chronicles", image: IMG9, description: 'Book Design | Photoshop' },
  { id: 10, category: 'web', title: '3 Medeiros', image: IMG10, description: 'Maquette Web | Illustrator' },
  { id: 11, category: 'design', title: 'Helianthus', image: IMG11, description: 'Product Design | Illustrator' },
  { id: 12, category: 'design', title: 'Proche aidant pour tous', image: IMG12, description: 'Trifold Brochure | Illustrator' },
  { id: 13, category: 'web', title: 'Daillac', image: IMG13, description: 'Développement Web | Wordpress' },
  { id: 14, category: 'web', title: 'Bijoux Ovalia', image: IMG14, description: 'Développement Web | Wordpress' },
  { id: 15, category: 'web', title: 'Pur Pasteur', image: IMG15, description: 'Développement Web | Wordpress' },
  { id: 16, category: 'web', title: 'Proche aidant pour tous', image: IMG16, description: 'Développement Web | Wordpress' },
  { id: 17, category: 'web', title: 'Formétal', image: IMG17, description: 'Développement Web | Wordpress' }
  // ...add more portfolio items
];

const Portfolio = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const itemsPerPage = 12;
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);

  const openModal = (image) => {
    setModalImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleFilterClick = (category) => {
    setSelectedCategory(category);
    if (category === 'all') {
      setVisibleItems(itemsPerPage);
    }
  };

  const handleShowMoreClick = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + 6);
  };

  const filteredPortfolio = selectedCategory === 'all'
    ? portfolioData
    : portfolioData.filter(item => item.category === selectedCategory);

  return (
    <>

      <section id="portfolio">
        <h3>Ils nous ont fait confiance</h3>
        <div class="slider">
          <div class="slider-wrap">
            <div class="slide">
              <img src={CLIENT1} className="slide-image" alt="logo de comète studio" />
            </div>
            <div class="slide">
              <img src={CLIENT2} className="slide-image" alt="logo de daillac" />
            </div>
            <div class="slide">
              <img src={CLIENT3} className="slide-image" alt="logo de pur pasteur" />
            </div>
            <div class="slide">
              <img src={CLIENT4} className="slide-image" alt="logo de bijouc ovalia" />
            </div>
            <div class="slide">
              <img src={CLIENT5} className="slide-image" alt="logo de proche aidant pour tous" />
            </div>
            <div class="slide">
              <img src={CLIENT6} className="slide-image" alt="logo de Tremblant Chalet" />
            </div>

            <div class="slide">
              <img src={CLIENT1} className="slide-image" alt="logo de comète studio" />
            </div>
            <div class="slide">
              <img src={CLIENT2} className="slide-image" alt="logo de daillac" />
            </div>
            <div class="slide">
              <img src={CLIENT3} className="slide-image" alt="logo de pur pasteur" />
            </div>
            <div class="slide">
              <img src={CLIENT4} className="slide-image" alt="logo de bijoux ovalia" />
            </div>
            <div class="slide">
              <img src={CLIENT5} className="slide-image" alt="logo de proche aidant pour tous" />
            </div>
            <div class="slide">
              <img src={CLIENT6} className="slide-image" alt="logo de Tremblant Chalet" />
            </div>
          </div>
        </div>

        <div className="filters">
          <div className="filter-buttons">
            <button
              className={`filter-btn ${selectedCategory === 'all' ? 'active' : ''}`}
              onClick={() => handleFilterClick('all')}
            >
              Tous
            </button>
            <button
              className={`filter-btn ${selectedCategory === 'web' ? 'active' : ''}`}
              onClick={() => handleFilterClick('web')}
            >
              Développement Web
            </button>
            <button
              className={`filter-btn ${selectedCategory === 'design' ? 'active' : ''}`}
              onClick={() => handleFilterClick('design')}
            >
              Design Graphique
            </button>
            <button
              className={`filter-btn ${selectedCategory === 'direction' ? 'active' : ''}`}
              onClick={() => handleFilterClick('direction')}
            >
              Direction Artistique
            </button>
            {/* Add more filter buttons... */}
          </div>
        </div>

        <div className="portfolio">
          {/* Render portfolio items */}
        </div>

        <div className="portfolio">
          {filteredPortfolio.slice(0, visibleItems).map(item => (
            <figure
              className="portfolio__img"
              key={item.id}
              onClick={() => openModal(item.image)}
            >
              <img src={item.image} alt={item.title} />
              <figcaption>
                <h4>{item.title}</h4>
                <p>{item.description}</p>
              </figcaption>
            </figure>
          ))}
        </div>

        {filteredPortfolio.length > visibleItems && (
          <div className="show-more-button">
            <button onClick={handleShowMoreClick}>Voir plus de projets 🖍️</button>
          </div>
        )}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          overlayClassName="ReactModal__Overlay"
          className="ReactModal__Content"
        >
          <button className="ModalCloseButton" onClick={closeModal}>
            X
          </button>
          {modalImage && <img src={modalImage} alt="Modal Content" className="ModalImage" />}
        </Modal>

      </section>

    </>
  );
};

export default Portfolio;