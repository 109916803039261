import React from 'react'
import NavBavards from '../lesbavards/NavBavards'
import Footer from '../../components/footer/Footer'
import './vols.css'
import VOL5 from '../../assets/bavards/vol5.webp'
import { useNavigate } from 'react-router-dom';
import blogPosts from '../lesbavards/blogPosts';

const Vol5 = () => {
    const navigate = useNavigate();
    const lastBlogPost = blogPosts[0];

    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className="vols-tweaks">
            <NavBavards />
            <div className="content__hider"></div>
            <div className="bavards-wrapper">
                <div className="bavards__container vol-wrapper">
                    <button onClick={goBack}>← retour en arrière</button>
                    <img className='vol__img' src={VOL5} alt="Illustration de plusieurs éléments web" />
                    <div className="title__vol">au–dela des clics – les essentiels en web</div>
                    <div className="author-date__vol">
                        <span className="date">17 mai 2023</span>
                        <span>|</span>
                        <span className="author"> Vincent Royer</span>
                    </div>
                    <span className="volume">Volume 5</span>
                    <div className="vol__article">
                        <p>Dans l'univers numérique actuel, se démarquer de la concurrence est essentiel pour atteindre de nouveaux sommets. Et pour y parvenir, rien de tel qu'un site web qui fait toute la différence. Découvrez les clés du design web qui vous propulseront en avant. Du minimalisme élégant aux combinaisons de couleurs saisissantes, en passant par l'interactivité immersive et une typographie distinctive, créez un site web qui fait sensation et vous positionne en tête de la compétition.</p>
                        <br></br>
                        <h3>La magie du minimalisme</h3>
                        <p><i>less is more.</i> Le minimalisme est un concept intemporel qui favorise la simplicité et l'épure. Optez pour des mises en page épurées, des espaces vides stratégiquement placés et des typographies nettes. En privilégiant le minimalisme, vous mettez en valeur votre contenu et offrez une expérience utilisateur fluide et agréable.</p>
                        <h3>Des combinaisons de couleurs audacieuses</h3>
                        <p>Osez utiliser des combinaisons de couleurs dynamiques et audacieuses. Du rouge vif au bleu électrique, les couleurs éclatantes ajoutent de l'énergie et de la vivacité à votre site. Jouez également avec les contrastes pour créer des effets visuels percutants et attirer l'attention des visiteurs.</p>
                        <h3>L'interactivité pour une expérience immersive</h3>
                        <p>Animez votre site web en intégrant des éléments interactifs. Des animations subtiles, des effets de défilement ou des vidéos intégrées captiveront vos visiteurs et les encourageront à explorer davantage. L'interaction crée une expérience immersive qui marque les esprits.</p><br />
                        <h3>Une typographie distinctive et originale</h3>
                        <p>Faites en sorte que vos mots se démarquent avec une typographie distinctive et originale. Choisissez des polices qui reflètent l'identité de votre marque et ajoutez une touche de créativité. En jouant avec la graisse, l'italique ou des combinaisons uniques, laissez votre typographie raconter une histoire à part entière.</p><br />
                        <h3>Mobile-friendly, un impératif</h3>
                        <p>Assurez-vous que votre site est prêt pour les appareils mobiles. L'expérience mobile est désormais primordiale, alors optez pour un design réactif qui s'adapte parfaitement à tous les écrans. N'oubliez pas d'optimiser les temps de chargement pour éviter toute frustration chez vos visiteurs.</p><br/>
                        <p>En résumé, la maîtrise des nuances chromatiques est une compétence essentielle pour tout créatif. En comprenant la signification de chaque tonalité, comment elles fonctionnent ensemble et la psychologie associée, vous pouvez créer des designs qui communiquent efficacement avec votre public cible et suscitent une réponse émotionnelle positive.</p>
                    </div>
                </div>
            </div>
            <Footer lastBlogPost={lastBlogPost} />
        </div>
    )
}

export default Vol5;