import React from 'react';
import NavBavards from '../../pages/lesbavards/NavBavards';
import Footer from '../../components/footer/Footer';
import './lesbavards.css';
import { Link } from 'react-router-dom';
import blogPosts from './blogPosts';

const LesBavards = () => {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  const lastBlogPost = blogPosts[0];

  return (
    <div id="top" className="bavards-menu">
      <div className="content__hider"></div>
      <NavBavards />
      <div className="bavards-wrapper">
        <div className="bavards__container">
          <div className="bavards__hero">
            <div className="bavards__left"></div>
            <div className="bavards__right"></div>
          </div>

          <div class="marquee">
            <div class="marquee__group">
              <span>Identite &nbsp;• &nbsp;</span><span> Graphisme &nbsp;• &nbsp;</span>
              <span>Web &nbsp;• &nbsp;</span><span> Tendances &nbsp;• &nbsp;</span>
              <span>Vie d'agence &nbsp;• &nbsp;</span><span> Actualites &nbsp;• &nbsp;</span>
              <span>Psychologie &nbsp;• &nbsp;</span><span id="misc"> Typographie &nbsp;• &nbsp;</span>
            </div>
            <div class="marquee__group">
              <span>Identite &nbsp;• &nbsp;</span><span> Graphisme &nbsp;• &nbsp;</span>
              <span>Web &nbsp;• &nbsp;</span><span> Tendances &nbsp;• &nbsp;</span>
              <span>Vie d'agence &nbsp;• &nbsp;</span><span> Acutlaites &nbsp;• &nbsp;</span>
              <span>Psychologie &nbsp;• &nbsp;</span><span id="misc"> Typographie &nbsp;• &nbsp;</span>
            </div>
          </div>


          {blogPosts.map((post, index) => (
            <Link
              key={index}
              to={post.link}
              className="article__effect"
              onClick={scrollToTop}
            >
              <div className="article__wrapper">
                <div className="article__left">
                  <img src={post.imageSrc} alt={`Illustration for ${post.title}`} />
                </div>
                <div className="article__right">
                  <h2>{post.title}</h2>
                  <span className="category">{post.category}</span>
                  <span className="author">{post.author}</span>
                  <span className="date">{post.date}</span>
                  <span className="volume">{post.volume}</span>
                  <a class="link" href="">
                    <span class="mask">
                      <div class="link-container">
                        <span class="link-title1 title">lire l'article</span>
                        <span class="link-title2 title">lire l'article</span>
                      </div>
                    </span>
                    <div class="link-icon">
                      <svg class="icon" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                        <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                      </svg>
                      <svg class="icon" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                        <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <Footer lastBlogPost={lastBlogPost} />

    </div>
  );
};

export default LesBavards;
