import React from 'react';
import './contactpage.css';
import NavBavards from '../lesbavards/NavBavards'
import Footer from '../../components/footer/Footer'
import { useEffect, useState } from "react"
import InputField from "./InputField"
import TextareaField from "./TextareaField";
import emailjs from 'emailjs-com';
import Contactright from '../../assets/contact-right.webp'
import blogPosts from '../lesbavards/blogPosts';
import useRef from 'react';


const Contactpage = () => {
    const [values, setValues] = useState({
        fullName: '',
        email: '',
        role: '',
        message: ''
    });
    const [status, setStatus] = useState('');
    const lastBlogPost = blogPosts[0];
    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.send('service_xvjk9x4', 'template_w9k250j', values, 'Rz8EsfQbEhaPAHd71')
            .then(response => {
                console.log('SUCCESS!', response);
                setValues({
                    fullName: '',
                    email: '',
                    role: '',
                    message: ''
                });
                setStatus('SUCCESS');
            }, error => {
                console.log('FAILED...', error);
            });
    }

    useEffect(() => {
        if (status === 'SUCCESS') {
            setTimeout(() => {
                setStatus('');
            }, 1000);
        }
    }, [status]);

    const handleChange = (e) => {
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }))
    }
    return (
        <div id="top" className="contactpage-menu">
            <div className="content__hider"></div>
            <NavBavards />
            <div className="bavards-wrapper">
                <div className="contact-wrapper" id="contact">
                    <section className='contact-form'>
                        <h3 className="">Jase-nous de ton projet!</h3>
                        {status && renderAlert()}
                        <form onSubmit={handleSubmit}>
                            <InputField value={values.fullName} handleChange={handleChange} label="Nom complet" name="fullName" type="text" placeholder="swayed" />
                            <InputField value={values.email} handleChange={handleChange} label="Courriel" name="email" type="email" placeholder="swayed@example.com" />
                            <TextareaField value={values.message} handleChange={handleChange} label="Ton message" name="message" placeholder="votre message" />
                            <button type="submit" className="btn btn-primary form-btn">Envoyer</button>
                        </form>
                    </section>
                    <div className="contact-right">
                        <img src={Contactright} alt="illustration d'un arobase"/>
                    </div>
                </div>
            </div>
            <Footer lastBlogPost={lastBlogPost} />
        </div>
    )
}
const renderAlert = () => (
    <div className="">
        <p>Ton message à été envoyé avec succès.</p>
    </div>

)

export default Contactpage;