import React from 'react'
import NavBavards from '../../pages/lesbavards/NavBavards'
import Footer from '../../components/footer/Footer'
import './vols.css'
import VOL1 from '../../assets/bavards/vol1.webp'
import blogPosts from '../lesbavards/blogPosts';
import { useNavigate } from 'react-router-dom';

const Vol1 = () => {
    const navigate = useNavigate();
    
    const lastBlogPost = blogPosts[0];
    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className="vols-tweaks">
            <NavBavards />
            <div className="content__hider"></div>
            <div className="bavards-wrapper">
                <div className="bavards__container vol-wrapper">
                    <button onClick={goBack}>← retour en arrière</button>
                    <img className='vol__img' src={VOL1} alt="Illustration d'un exemple de charte graphique" />
                    <div className="title__vol">comment creer sa charte graphique</div>
                    <div className="author-date__vol">
                        <span className="date">24 févirer 2023</span>
                        <span>|</span>
                        <span className="author"> Vincent Royer</span>
                    </div>
                    <span className="volume">Volume 1</span>
                    <div className="vol__article">
                        <p>Que tu sois à la tête d'une petite entreprise, d'une organisation sans but lucratif ou que tu sois un créateur indépendant, il est crucial de comprendre l'importance d'une charte graphique pour donner une identité visuelle cohérente et reconnaissable à ta marque. Cette charte est composée d'un ensemble de règles et de directives visuelles qui définissent l'apparence et l'utilisation de ton logo, de tes couleurs, de tes polices et d'autres éléments graphiques sur tous tes supports de communication. Une charte graphique bien conçue peut t'aider à renforcer la confiance de tes clients, à améliorer la reconnaissance de ta marque et à rendre ton image de marque plus professionnelle et cohérente. En suivant les bonnes pratiques de la création de charte graphique, tu peux créer une image de marque qui se démarque de la concurrence et qui reflète au mieux ta personnalité et tes valeurs.</p>
                        <h3>Comprends l'identité de ta marque</h3>
                        <p>Avant de créer ta charte graphique, il est important de bien comprendre l'identité de ta marque. Pour ça, tu peux te poser des questions comme : quel est l'objectif de ma marque ? Quels sont nos valeurs ? Quel est mon public cible ? Quelle est ma position sur le marché par rapport à mes concurrents ? Cette réflexion te permettra de définir l'identité visuelle de ta marque, qui doit refléter ta personnalité et ton positionnement.</p>
                        <h3>Choisir les couleurs</h3>
                        <p>Le choix des couleurs est crucial pour créer une charte graphique. Les couleurs doivent être choisies en fonction de l'identité de ta marque, et doivent évoquer les émotions que tu souhaites transmettre à ton public cible. Il est important de choisir des couleurs qui se complètent et qui peuvent être utilisées dans différents contextes, que ce soit sur ton site web, dans tes brochures, ou dans tes publicités.</p>
                        <h3>Définir les typographies</h3>
                        <p>La typographie est un élément important de ta charte graphique. Elle doit être claire, lisible et refléter l'identité de ta marque. Tu peux utiliser différentes typographies pour les titres, les sous-titres et le corps du texte, mais il est important de choisir des typographies qui se complètent et qui peuvent être utilisées dans différents contextes. Il est également important de définir les tailles et les espacements entre les différentes typographies.</p>
                        <h3>Créer le logo</h3>
                        <p>Le logo est le symbole de ton entreprise, il doit être facilement reconnaissable et refléter l'identité de ta marque. Il doit être conçu de manière à pouvoir être utilisé dans différents formats (par exemple, sur une carte de visite ou sur un panneau publicitaire) tout en restant lisible et identifiable. Tu peux créer ton logo toi-même ou faire appel à un professionnel pour t'aider.</p>
                        <h3>Définir les règles d'utilisation de la charte graphique</h3>
                        <p>Une fois que tu as choisi les couleurs, les typographies et créé ton logo, il est important de définir les règles d'utilisation de ta charte graphique. Ces règles doivent décrire comment utiliser les éléments graphiques de ta charte, et doivent inclure des directives sur les tailles minimales du logo, les couleurs à utiliser pour le texte et les images, les marges et les espaces entre les éléments, etc. Ces règles permettent de garantir la cohérence de ta communication visuelle.</p>
                        <h3>Mettre en place la charte graphique</h3>
                        <p>Enfin, une fois que ta charte graphique est créée et que les règles d'utilisation sont définies, il est important de la mettre en place. Tu dois t'assurer que tous les supports de communication de ta marque respectent les règles de ta charte graphique. Pour cela, tu peux créer des modèles de documents, des présentations et des designs qui respectent la charte graphique de ta marque. De cette manière, tu pourras garantir la cohérence de ta communication visuelle, renforcer l'identité de ta marque et ainsi la rendre plus facilement reconnaissable.</p>
                        <br></br><p>N'oublie pas que ta charte graphique est un document évolutif et qu'elle peut être modifiée au fil du temps pour s'adapter aux changements de ton entreprise ou des tendances du marché. En fin de compte, une charte graphique forte et cohérente t'aidera à te démarquer et à communiquer efficacement avec ton public cible, renforçant ainsi ton image de marque et ton positionnement sur le marché.</p>
                    </div>
                </div>
            </div>
            <Footer lastBlogPost={lastBlogPost} />
        </div>
    )
}

export default Vol1;