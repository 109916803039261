import React from 'react'
import './contact.css'
const TextareaField = (props) => {
    const { handleChange, label, name, value } = props;
    return (
      <div>
        <label className="" placeholder='' htmlFor={name}>{label}</label>
        <textarea placeholder='' onChange={handleChange} name={name} rows="4" className="" value={value}></textarea>
      </div>
    )
  }
  
  export default TextareaField