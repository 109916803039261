import './nav.css';
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { BsFacebook } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';
import { BsSpotify } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const Nav = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  
  const handleClick = (event, nav) => {
    event.preventDefault();
    hide();
    navigate(nav.link);
  
    if (nav.link === '/#portfolio') {
      scrollToSection('portfolio');
    } else if (nav.link === '/LesBavards') {
      scrollToTopForLesBavards();
    } else if (nav.link === '/Contactpage') {
      scrollToTopForContactpage();
    } else {
      scrollToTop();
    }
  };
  
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  const scrollToTopForLesBavards = () => {
    // Add code here to scroll to the top of LesBavards page
    scrollToTop();
  };
  
  const scrollToTopForContactpage = () => {
    // Add code here to scroll to the top of Contactpage
    scrollToTop();
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const hide = () => setIsOpen(false);
  const show = () => setIsOpen(true);

  //Close nav when escape key is pressed
  useEffect(() => {
    const closeMenu = (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };
    window.addEventListener('keydown', closeMenu);
    return () => {
      window.removeEventListener('keydown', closeMenu);
    };
  }, []);

  const targetRef = useRef();
  useLayoutEffect(() => {
    document.body.style.marginTop = targetRef.current.offsetHeight + 'px';
  });

  const navigation = [
    { link: '/Agence', text: 'Agence' },
    { link: '/expertise', text: 'Expertise' },
    { link: '/#portfolio', text: 'Portfolio' },
    { link: '/LesBavards', text: 'Les Bavards' },
    { link: '/Contactpage', text: 'Contact' },
  ];

  useEffect(() => {
    const closeMenu = (event) => {
      if (!event.target.closest('.menu-links')) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('click', closeMenu);
    }
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [isOpen]);

  return (
    <nav ref={targetRef} className="nav-wrapper">
      <div className="button-wrapper">
        <button
          id="menu-toggle"
          className={`menu-toggle ${isOpen ? 'active' : ''}`}
          onClick={toggle}
          title="menu"
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </button>
      </div>
      <ul className={`menu-links ${isOpen ? 'show' : ''}`}>
      <span className="sswayed">S.</span>
        {navigation.map((nav, index) => (
          <li key={index}>
            <a href={nav.link} onClick={(event) => handleClick(event, nav)} className="nav__menu__link">
              {nav.text}
            </a>
          </li>
        ))}
        <div className="social__menu">
          <a href="https://www.facebook.com/profile.php?id=100083720064113" rel="noopener noreferrer" target="_blank"><BsFacebook /></a>
          <a href="https://www.instagram.com/swayedoriginal/" rel="noopener noreferrer" target="_blank" ><BsInstagram /></a>
          <a href="https://open.spotify.com/user/21mset5dtora36e2d2sd663xi?si=2565903794ee4a85" rel="noopener noreferrer" target="_blank"><BsSpotify /></a>
        </div>
      </ul>
    </nav>
  );
};

export default Nav;
