import React from 'react'

const CTA = () => {
  return (
    <div className='cta'>
      <div id="button-4">
         <a href="#portfolio" className='btn'>portfolio</a>
         <div id="underline"></div>
        </div>
        <div id="button-4">
          <a href="#contact" className='btn btn-primary'>écris-nous</a>
          <div id="underline"></div>
        </div>
    </div>
  )
}


export default CTA