import React from 'react'
import './contact.css'
const InputField = (props) => {
    const { handleChange, label, name, type, value } = props;
    return (
      <div className="">
        <label className="" placeholder='' htmlFor={name}>{label}</label>
        <input className="" placeholder='' type={type} onChange={handleChange} value={value} name={name} required/>
      </div>
    )
  }
  
  export default InputField