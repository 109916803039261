import React from 'react'
import { useEffect, useState } from "react"
import InputField from "./InputField"
import TextareaField from "./TextareaField";
import emailjs from 'emailjs-com';
import './contact.css'
import Fire from '../../assets/fire.webp'

const Contact = () => {
  const [values, setValues] = useState({
    fullName: '',
    email: '',
    role: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send('service_xvjk9x4', 'template_w9k250j', values, 'Rz8EsfQbEhaPAHd71')
      .then(response => {
        console.log('SUCCESS!', response);
        setValues({
          fullName: '',
          email: '',
          role: '',
          message: ''
        });
        setStatus('SUCCESS');
      }, error => {
        console.log('FAILED...', error);
      });
  }

  useEffect(() => {
    if (status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
      }, 2000);
    }
  }, [status]);

  const handleChange = (e) => {
    setValues(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }
  return (
    <div className="contact__container" id="contact">
      <img src={Fire} className="fire" alt="fire"/>
      <h3 className="">Jase-nous de ton projet!</h3>
      <section className='form__container'>
        {status && renderAlert()}
        <form onSubmit={handleSubmit}>
          <InputField value={values.fullName} handleChange={handleChange} label="Nom complet" name="fullName" type="text" placeholder="swayed" />
          <InputField value={values.email} handleChange={handleChange} label="Courriel" name="email" type="email" placeholder="swayed@example.com" />
          <TextareaField value={values.message} handleChange={handleChange} label="Ton message" name="message" placeholder="votre message"/>
          <button type="submit" className="contact-btn">Envoyer</button>
        </form>
      </section>
    </div>
  )
}

const renderAlert = () => (
  <div className="">
    <p>Ton message à été envoyé avec succès.</p>
  </div>
)
  

export default Contact