import React from 'react'
import Nav from './../../components/nav/Nav'
import Header from './../../components/header/Header'
import About from './../../components/about/About'
import Experience from './../../components/experience/Experience'
import Services from './../../components/services/Services'
import Portfolio from './../../components/portfolio/Portfolio'
import Ask from './../../components/ask/Ask'
import Contact from './../../components/contact/Contact'
import Footer from './../../components/footer/Footer'
import blogPosts from '../lesbavards/blogPosts'
import CookieBanner from './CookieBanner';

const Home = () => {
const lastBlogPost = blogPosts[0];

  return (
    <div id="top">
      <Header/>
      <Nav/>
      <About/>
      <Experience/>
      <Services/>
      <Portfolio/>
      <Ask/>
      <Contact/>
      <CookieBanner/>
      <Footer lastBlogPost={lastBlogPost} />
    </div>
  )
}

export default Home
