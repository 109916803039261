import React from 'react';
import './footer.css';
import { BsFacebook } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';
import { BsSpotify } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Footer = ({ lastBlogPost }) => {
  const today = new Date();
  const year = today.getFullYear();
  const [agenceClicked] = useState(false);

  const handleLinkClick = (event) => {
    const href = event.target.getAttribute("href");
    if (!href.startsWith("#")) {
      event.preventDefault();
      window.location.href = href;
    }
  };


  return (
    <footer>
      <div className="tiles__footer">
        <div className="tile__left">
          <h3>articles récents</h3>
          <div className="latest-posts">
            <h3>{lastBlogPost.title}</h3>
            <a href={lastBlogPost.link} className="read-article-button">
              Lire l'article
            </a>
          </div>
          {/*<img src={lastBlogPost.imageSrc} alt={lastBlogPost.title} />*/}
        </div>
        <a className="tile__right" href="/contactPage">
          <div>
            <h3>Débuter un projet</h3>
            <span>→</span>
          </div>
        </a>
      </div>

      <Link to="/" className="footer__logo">
        <span className="logo">Swayed</span>
      </Link>

      <ul className={agenceClicked ? 'permalinks expertise-background' : 'permalinks'}>
        <li>
          <Link to="/" onClick={handleLinkClick}>
            Accueil
          </Link>
        </li>
        <li>
          <Link to="/Agence" onClick={handleLinkClick}>
            Agence
          </Link>

        </li>
        <li>
          <Link to="/expertise" onClick={handleLinkClick}>
            Expertise
          </Link>
        </li>
        <li>
          <Link to="#portfolio" onClick={handleLinkClick}>
            Portfolio
          </Link>

        </li>
        <li>
          <Link to="/LesBavards" onClick={handleLinkClick}>
            Les Bavards
          </Link>
        </li>
        <li>
          <Link to="/Contactpage" onClick={handleLinkClick}>
            Contact
          </Link>
        </li>
      </ul>

      <div className="footer__socials">
        <a
          href="https://www.facebook.com/profile.php?id=100083720064113"
          target="_blank"
          rel="noopener noreferrer"
          className="social-menu"
        >
          <BsFacebook />
        </a>
        <a
          href="https://www.instagram.com/swayedoriginal/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-menu"
        >
          <BsInstagram />
        </a>
      </div>

      <div className="footer__copyrights__wrapper">
        <div className="footer__copyright">
          Tous droits réservés {year} &copy; Agence créative Swayed &nbsp;| &nbsp;
        </div>
        <div className="politique"><a href="/Politique"> Politique de confidentialité</a></div>
      </div>
    </footer>
  );
};

export default Footer;
