import React from 'react';
import './agence.css';
import { useState, useEffect } from 'react';
import NavBavards from '../lesbavards/NavBavards'
import Footer from '../../components/footer/Footer'
import Agenceimg from '../../assets/agenceimg.webp'
import Agencesticker from '../../assets/marketing.webp'
import Bomb from '../../assets/bomb.webp'
import { Link } from 'react-router-dom';
import blogPosts from '../lesbavards/blogPosts';


const Agence = () => {
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        if (window.location.pathname === '/Agence') {
          setBgClass('expertise-background');
        } else {
          setBgClass('');
        }
      }, []);
      
    const [bgClass, setBgClass] = useState('');

    const [liked, setLiked] = useState(localStorage.getItem('liked') === 'true');
    const [disliked, setDisliked] = useState(localStorage.getItem('disliked') === 'true');

    const handleLike = () => {
        if (!liked && !disliked) { // check if the user has already liked or disliked the content
            localStorage.setItem('liked', 'true'); // store the user's like
            setLiked(true);
        }
        setDisliked(false); // disable the dislike button
    };

    const handleDislike = () => {
        if (!disliked && !liked) { // check if the user has already disliked or liked the content
            localStorage.setItem('disliked', 'true'); // store the user's dislike
            setDisliked(true);
        }
        setLiked(false); // disable the like button
    };
    const lastBlogPost = blogPosts[0];

    return (

        <div id="agence" className='expertise-page'>
            <div className="content__hider"></div>
            <NavBavards />
            <div className={`bavards-wrapper ${bgClass}`}>
                <div className="agence-wrapper">
                    <h2>Un engagement envers l'innovation et le design de qualité</h2>
                    <div className="agence-description">
                        <img src={Agenceimg} alt="agence" />
                        <p>Nous sommes une agence créative passionnée par le design et la communication. Nous sommes déterminés à aider nos clients à raconter leur histoire de manière unique et mémorable. Nous croyons en l'importance d'une collaboration étroite avec nos clients pour créer des solutions innovantes et efficaces qui répondent à leurs besoins spécifiques.</p>
                        <img src={Agencesticker} className="agencesticker" alt="Illustration du signe peace"/>
                    </div>
                    <div className="agence-ethymologie">
                        <h2>swayed</h2>
                        <p>Jongler avec certains <span className="vincent">concepts</span> pour les faire briller.</p>
                        <p>"J'ai swayed avec l'idée de lancer une nouvelle ligne de <span className="vincent">produits</span> pour notre <span className="vincent">entreprise</span> et finalement, cela a été un <span className="vincent">succès</span> retentissant."</p>
                        <p className="ethymologie-author">Par <span className="vincent">Vincent</span> 30 mars 2023</p>
                        <div class="agence-btns-container">
                            <div class="agence-btn-wrapper">
                                <button class="agence-btn" onClick={handleLike}>Like ({liked ? 1 : 0})</button>
                                <button class="agence-btn" onClick={handleDislike}>Dislike ({disliked ? 1 : 0})</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="marquee-wrapper">
                    <div class="marquee">
                        <div class="marquee__group">
                            <p>Adaptabilité</p>
                            <p>Adaptabilité</p>
                            <p className='skate'>Adaptabilité</p>
                            <p>Adaptabilité</p>
                            <p aria-hidden="true">Adaptabilité</p>
                            <p aria-hidden="true" className='skate'>Adaptabilité</p>
                            <p aria-hidden="true">Adaptabilité</p>
                            <p aria-hidden="true">Adaptabilité</p>
                        </div>
                        <div aria-hidden="true" class="marquee__group">
                            <p>Adaptabilité</p>
                            <p>Adaptabilité</p>
                            <p>Adaptabilité</p>
                        </div>
                    </div>
                    <div class="marquee">
                        <div class="marquee__group">
                            <p>Innovation</p>
                            <p>Innovation</p>
                            <p>Innovation</p>
                            <p className='innovation'>Innovation</p>
                            <p aria-hidden="true">Innovation</p>
                            <p aria-hidden="true">Innovation</p>
                            <p aria-hidden="true" className='innovation'>Innovation</p>
                            <p aria-hidden="true">Innovation</p>
                        </div>
                        <div aria-hidden="true" class="marquee__group">
                            <p>Innovation</p>
                            <p>Innovation</p>
                            <p>Innovation</p>
                        </div>
                    </div>
                    <div class="marquee">
                        <div class="marquee__group">
                            <p>Intégrité</p>
                            <p>Intégrité</p>
                            <p>Intégrité</p>
                            <p className='integrite'>Intégrité</p>
                            <p aria-hidden="true">Intégrité</p>
                            <p aria-hidden="true">Intégrité</p>
                            <p aria-hidden="true" className='integrite'>Intégrité</p>
                            <p aria-hidden="true">Intégrité</p>
                        </div>
                        <div aria-hidden="true" class="marquee__group">
                            <p>Intégrité</p>
                            <p>Intégrité</p>
                            <p>Intégrité</p>
                        </div>
                    </div>
                    <div class="marquee">
                        <div class="marquee__group">
                            <p>Collaboration</p>
                            <p>Collaboration</p>
                            <p>Collaboration</p>
                            <p className='collaboration'>Collaboration</p>
                            <p aria-hidden="true">Collaboration</p>
                            <p aria-hidden="true" className='collaboration'>Collaboration</p>
                            <p aria-hidden="true">Collaboration</p>
                            <p aria-hidden="true">Collaboration</p>
                        </div>
                        <div aria-hidden="true" class="marquee__group">
                            <p>Collaboration</p>
                            <p>Collaboration</p>
                            <p>Collaboration</p>
                        </div>
                    </div>
                </div>

                <div className="mission-wrapper">
                    <h2>Notre quête de <span class="originalite">l'originalité</span></h2>
                    <p>Chez Swayed, notre mission est d'apporter une créativité novatrice à chaque projet que nous entreprenons. Nous croyons que la clé de la réussite réside dans notre capacité à repousser les limites, à sortir des sentiers battus et à oser explorer de nouvelles voies. Nous sommes convaincus que la créativité est l'élément clé qui permet à nos clients de se démarquer dans leur marché et de réussir. C'est pourquoi nous mettons tout en œuvre pour créer des projets uniques, qui reflètent l'essence même de leur entreprise et qui captivent leur public cible. Nous sommes fiers de faire preuve d'audace, d'innovation et de détermination à chaque étape de notre processus créatif.</p>
                    <img src={Bomb} className="bomb" alt="illustration d'une bombe"/>
                </div>

                    <div className="ariane"><h4>Contact</h4></div>
                    <div className="expertise-contact">
                        <h2>Venez nous jaser!</h2>
                        <div class="container-btn">
                            <Link to="/Contactpage" className="expertise-btn" onClick={scrollToTop}>Nous joindre</Link>
                        </div>
                    </div>
            </div>
            <Footer lastBlogPost={lastBlogPost} />
        </div >
    );
}

export default Agence;