import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookieBanner.css'; // Assurez-vous d'ajuster le chemin vers votre fichier CSS de styles

function CookieBanner() {
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    // Vérifiez si l'utilisateur a déjà donné son consentement en consultant le cookie 'consentGiven'
    const hasConsent = Cookies.get('consentGiven') === 'true';
    setConsentGiven(hasConsent);
  }, []);

  const handleAccept = () => {
    // Définissez un cookie nommé 'consentGiven' pour stocker l'accord de l'utilisateur
    Cookies.set('consentGiven', 'true', { expires: 365 }); // Exemple : cookie expire après 365 jours
    setConsentGiven(true); // Mettez à jour l'état pour masquer la bannière
  };

  const handleDecline = () => {
    // Vous pouvez ajouter votre propre logique pour gérer le refus des cookies ici
    // Par exemple, ne pas définir de cookies tiers
    setConsentGiven(true); // Mettez à jour l'état pour masquer la bannière
  };

  if (consentGiven) {
    return null; // La bannière n'est plus affichée une fois que l'utilisateur a donné son consentement.
  }

  return (
    <div className="cookie-banner">
      <p>En continuant d'utiliser ce site, vous acceptez l'utilisation de cookies. Consultez notre <a href="/Politique"> politique de confidentialité</a> pour plus d'informations.</p>
      <button onClick={handleAccept}>Accepter</button>
      <button onClick={handleDecline}>Refuser</button>
    </div>
  );
}

export default CookieBanner;
