import React from 'react'
import NavBavards from '../lesbavards/NavBavards'
import Footer from '../../components/footer/Footer'
import './vols.css'
import VOL2 from '../../assets/bavards/vol2.webp'
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import blogPosts from '../lesbavards/blogPosts';

const Vol2 = () => {
    const navigate = useNavigate();
    
    const lastBlogPost = blogPosts[0];
    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className="vols-tweaks">
            <NavBavards />
            <div className="content__hider"></div>
            <div className="bavards-wrapper">
                <div className="bavards__container vol-wrapper">
                    <button onClick={goBack}>← retour en arrière</button>
                    <img className='vol__img' src={VOL2} alt="Texte Typographique répéter plusieurs fois" />
                    <div className="title__vol">quelle police d'ecriture choisir pour sa marque</div>
                    <div className="author-date__vol">
                        <span className="date">09 mars 2023</span>
                        <span>|</span>
                        <span className="author"> Vincent Royer</span>
                    </div>
                    <span className="volume">Volume 2</span>
                    <div className="vol__article">
                        <p>La police de caractères est un élément clé de tout projet de design. Elle peut communiquer des émotions, un style, une personnalité et une intention. Elle peut faire la différence entre un design moyen et un design extraordinaire. En tant que designer, vous devez donc vous assurer de choisir la bonne police pour votre projet.</p>
                        <h3>Déterminez l'objectif de votre projet</h3>
                        <p>Avant de choisir une police de caractères, vous devez déterminer l'objectif de votre projet. Quel est le message que vous voulez transmettre ? Souhaitez-vous que votre design soit élégant et sophistiqué ou plutôt amusant et ludique ? La police que vous choisissez doit correspondre à l'objectif de votre projet.</p>
                        <br></br><p>Par exemple, si vous travaillez sur un projet pour une entreprise de haute technologie, vous pouvez opter pour une police moderne et épurée pour communiquer l'innovation et la sophistication. Si vous travaillez sur un projet pour une entreprise de jouets, vous pouvez choisir une police ludique et amusante pour communiquer la joie et la créativité.</p>
                        <h3>Pensez à la lisibilité</h3>
                        <p>La lisibilité est un élément clé de tout projet de design. Vous devez vous assurer que la police que vous choisissez est facile à lire, même à une petite taille. Évitez les polices fantaisistes ou difficiles à déchiffrer. Optez plutôt pour une police claire et facile à lire.</p>
                        <br></br><p>Par exemple, une police de caractères scripte peut être difficile à lire à de petites tailles, ce qui peut nuire à la lisibilité de votre projet. Une police de caractères sans-serif, en revanche, est généralement plus facile à lire.</p>
                        <h3>Définir les typographies</h3>
                        <p>La typographie est un élément important de ta charte graphique. Elle doit être claire, lisible et refléter l'identité de ta marque. Tu peux utiliser différentes typographies pour les titres, les sous-titres et le corps du texte, mais il est important de choisir des typographies qui se complètent et qui peuvent être utilisées dans différents contextes. Il est également important de définir les tailles et les espacements entre les différentes typographies.</p>
                        <h3>Gardez à l'esprit la hiérarchie de l'information</h3>
                        <p>Dans tout projet de design, la hiérarchie de l'information est importante. Vous devez être en mesure de guider le lecteur à travers le contenu en utilisant différents niveaux de texte. Utilisez des polices différentes pour les titres, les sous-titres et le texte principal pour créer une hiérarchie claire.</p>
                        <br></br><p>Par exemple, vous pouvez utiliser une police de caractères sans-serif pour les titres et une police de caractères serif pour le corps du texte pour créer une distinction claire entre les différents niveaux de texte.</p>
                        <h3>Soyez cohérent</h3>
                        <p>La cohérence est importante en design. Utilisez une police cohérente dans tout votre projet pour créer une expérience harmonieuse pour le lecteur. Évitez de mélanger trop de polices différentes, cela peut rendre votre design trop chargé.</p>
                        <br></br><p>Par exemple, si vous utilisez une police de caractères moderne pour votre titre, utilisez la même police pour les sous-titres et le texte principal pour maintenir la cohérence dans votre projet.</p>
                        <h3>Évitez les tendances passagères</h3>
                        <br></br><p>Les tendances passagères sont tentantes, mais elles ne durent généralement pas longtemps. Évitez les polices qui sont populaires maintenant, mais qui risquent de sembler datées dans quelques mois ou années. Choisissez plutôt des polices intemporelles qui resteront pertinentes au fil du temps. </p>
                        <br></br><p>En résumé, le choix de la bonne police de caractères est crucial pour tout projet de design. Vous devez tenir compte de l'objectif de votre projet, de la lisibilité, de la hiérarchie de l'information, de la cohérence et éviter les tendances passagères. En suivant ces conseils, vous serez en mesure de choisir la police de caractères qui convient le mieux à votre projet et qui ajoutera une touche professionnelle et créative à votre design.</p>
                        <br></br><p>Chez Swayed, nous comprenons l'importance de chaque détail dans un projet de design. Nous sommes passionnés par la création de designs innovants et originaux pour nos clients. Si vous cherchez à donner vie à votre projet de design, n'hésitez pas à nous <HashLink to="/#contact" className='contacter'> contacter</HashLink>. Nous serions ravis de travailler avec vous pour créer un design exceptionnel qui correspond à vos objectifs et qui laisse une impression durable sur votre public.</p>
                    </div>
                </div>
            </div>
            <Footer lastBlogPost={lastBlogPost} />
        </div>
    )
}

export default Vol2;