import React, { useState, useRef, useEffect } from 'react';
import './navbavards.css';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavBavards = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isVolPage = currentPath.includes('/vols/');
  const isLesBavardsPage = currentPath.includes('/lesbavards/');

  const navClass = isVolPage ? 'vols-tweak' : isLesBavardsPage ? 'lesbavards-tweak' : '';

  const [showMenu, setShowMenu] = useState(false);
  const navRef = useRef(null);

  const handleClick = () => {
    setShowMenu(false);
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
    handleClick();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleMenuIconClick = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav ref={navRef} className={`navbar__bavards ${showMenu ? 'open' : ''}`} id="top">
      <div className={`navbar-bavards-container container-nav ${navClass}`}>
        <div className="menu-icon" onClick={handleMenuIconClick}>
          <i className={`fa ${showMenu ? 'fa-times' : 'fa-bars'}`}></i>
        </div>
        <ul className={`menu-items ${showMenu ? 'open' : ''}`}>
          <li>
            <Link
              to="/Agence"
              className={`hover-underline-animation ${currentPath === '/Agence' ? 'active-link' : ''}`}
              onClick={() => {
                handleLinkClick();
                handleClick();
              }}
            >
              Agence
            </Link>
          </li>

          <li>
            <Link
              to="/expertise"
              className={`hover-underline-animation ${currentPath === '/expertise' ? 'active-link' : ''}`}
              onClick={() => {
                handleLinkClick();
                handleClick();
              }}
            >
              Expertise
            </Link>
          </li>

          <li>
            <HashLink
              to="/#portfolio"
              className={`hover-underline-animation ${currentPath === '/' && location.hash === '#portfolio' ? 'active-link' : ''}`}
              onClick={() => {
                handleLinkClick();
                handleClick();
              }}
            >
              Portfolio
            </HashLink>
          </li>

          <li>
            <Link
              to="/LesBavards"
              className={`hover-underline-animation ${currentPath === '/LesBavards' ? 'active-link' : ''}`}
              onClick={() => {
                handleLinkClick();
                handleClick();
              }}
            >
              Les Bavards
            </Link>
          </li>

          <li>
            <Link
              to="/Contactpage"
              className={`hover-underline-animation ${currentPath === '/Contactpage' ? 'active-link' : ''}`}
              onClick={() => {
                handleLinkClick();
                handleClick();
              }}
            >
              Contact
            </Link>
          </li>
        </ul>
        <Link to="/" onClick={handleLogoClick}>
          <h2 className="logo">S.</h2>
        </Link>
      </div>
    </nav>
  );
};

export default NavBavards;
