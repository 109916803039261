import React from 'react'
import useLocalStorage from 'use-local-storage'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LesBavards from "./pages/lesbavards/LesBavards";
import Contactpage from "./pages/contactpage/Contactpage"
import Agence from "./pages/agence/Agence"
import Expertise from "./pages/expertise/Expertise"
import Politique from "./pages/politique/Politique"
import Home from "./pages/home/Home"
import Vol1 from './pages/vols/vol1'
import Vol2 from './pages/vols/vol2'
import Vol3 from './pages/vols/vol3'
import Vol4 from './pages/vols/vol4'
import Vol5 from './pages/vols/vol5'
import Vol6 from './pages/vols/vol6'
import MoonIcon from './assets/moon.webp'; // Import the moon icon
import SunIcon from './assets/sun.webp'; // Import the sun icon
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const App = () => {
  const [theme, setTheme] = useLocalStorage('theme' ? 'dark' : 'light')

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [isShipVisible, setIsShipVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const toggleShipVisibility = () => {
    setIsShipVisible(!isShipVisible);
    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
    }, 0); // Set the timeout to match your animation duration (0.5s in this case)
  };


  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme)
  }
  return (
    <div class="portfolio-theme" data-theme={theme}>
      <div class="switch-container">
        <input onClick={switchTheme} type="checkbox" id="switch" />
        <label htmlFor="switch">
          {/* Conditionally render the moon or sun icon based on the theme */}
          <img
            src={theme === 'dark' ? MoonIcon : SunIcon}
            className={`lamp ${isAnimating ? '' : 'fade-in'}`}
            alt={theme === 'dark' ? 'moon' : 'sun'}
            onClick={toggleShipVisibility}
          />

        </label>
      </div>
      <div className={`backtotop ${showBackToTop ? 'show' : ''}`}>
        <a href="#" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} size="lg" />
        </a>
      </div>
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/LesBavards" element={<LesBavards />} />
            <Route path="/Contactpage" element={<Contactpage />} />
            <Route path="/Agence" element={<Agence />} />
            <Route path="/Expertise" element={<Expertise />} />
            <Route path="/Politique" element={<Politique />} />
            <Route path="/vol1" element={<Vol1 />} />
            <Route path="/vol2" element={<Vol2 />} />
            <Route path="/vol3" element={<Vol3 />} />
            <Route path="/vol4" element={<Vol4 />} />
            <Route path="/vol5" element={<Vol5 />} />
            <Route path="/vol6" element={<Vol6 />} />
          </Routes>
        </BrowserRouter>
      </>
    </div>

  );
}
export default App