import React from 'react';
import './politique.css';
import Nav from './../../components/nav/Nav'
import Footer from '../../components/footer/Footer'
import blogPosts from '../lesbavards/blogPosts';

const PolitiqueDeConfidentialite = () => {
    const lastBlogPost = blogPosts[0];
    return (
        <div className='politique__content__wrapper'>
            <Nav />
            <h1>Politique de confidentialité</h1>

            <h2>Introduction</h2>
            <p>
                Devant le développement des nouveaux outils de communication, il est nécessaire de porter une
                attention particulière à la protection de la vie privée. C’est pourquoi nous nous engageons à
                respecter la confidentialité des renseignements personnels que nous collectons.
            </p>

            <h2>Responsable de la protection des renseignements personnels</h2>
            <p>
                Pour toutes questions liées à la collecte, l'utilisation, la communication, la conservation et la suppression de vos données personnelles, ou pour exercer vos droits d'accès, de correction ou de retrait, n'hésitez pas à contacter notre responsable de la protection des renseignements personnels :
            </p>
            <address>
                Vincent Royer<br />
                Fondateur<br />
                Courriel : info@swayedoriginal.com<br />
                Site Web : <a href="https://www.swayedoriginal.com/">https://www.swayedoriginal.com/</a>
            </address>

            <h2>Collecte des renseignements personnels</h2>
            <p>
                Les renseignements personnels que nous collectons sont les suivants :
                <ul className='politique__ul'>
                    <li>• Nom complet</li>
                    <li>• Adresse électronique</li>
                </ul>
            </p>
            <p>
                Ces informations sont obtenues via des formulaires et à travers l'interaction avec notre site Web. Nous utilisons également des témoins (cookies) et des journaux pour recueillir des informations vous concernant.
            </p>

            <h2>Formulaires et interactivité</h2>
            <p>
                Nous collectons vos informations personnelles à travers des formulaires, tels que le formulaire de contact. Ces données sont utilisées pour les finalités suivantes :
                <ul className='politique__ul'>
                    <li>• Statistiques</li>
                    <li>• Contact</li>
                </ul>
            </p>

            <h2>Témoins de connexion (cookies)</h2>
            <p>Les témoins de connexion sont des petits fichiers texte que les sites Web demandent à votre navigateur de stocker sur votre ordinateur ou appareil mobile.</p>
            <br /><p>Notre site Web utilise des témoins de connexion analytique Google Analytics, qui est un outil permettant de mesurer l'audience Web et d'analyser les visites sur notre site Web. À l'aide de témoins de connexion Google Analytics recueille des renseignements sur votre navigation, dont : l'adresse IP tronquée de votre ordinateur, (à complété). </p>
            <br /><p>Votre consentement est nécessaire pour l'utilisation de ces témoins de connexion. Chaque fois que vous visitez notre site Web vous êtes invités à accepter ou refuser ces témoins de connexion.</p>
            <h3>Bloquage des témoins de connexion analytique</h3>
            <p>Lorsque vous acceptez l'utilisation de témoins de connexion votre consentement est valide pour une durée de 365 jours. Vous pouvez retirer votre consentement en vidant la cache de votre navigateur.</p>
            <p>Agence créative Swayed utilise Google Analytics. Ce dernier peut conserver des renseignements à l'extérieur du Québec, notamment au État-Unis. Pour avoir plus d'informations sur l'utilisation des renseignements collectés, nous vous invitons à consulter <a href="https://marketingplatform.google.com/about/analytics/terms/">les conditions d'utilisation de Google Analytics</a> </p>
            <h2>Consentement</h2>
            <p>
                Vous avez le droit de vous opposer à l'utilisation de vos données personnelles pour certaines fins, ainsi que de demander la suppression de vos informations.

                Pour exercer ces droits, veuillez contacter notre responsable de la protection des renseignements personnels :
            </p>
            <address>
                Vincent Royer<br />
                Fondateur<br />
                Courriel : info@swayedoriginal.com<br />
                Site Web : <a href="https://www.swayedoriginal.com/">https://www.swayedoriginal.com/</a>
            </address>
            <h2>Droit d’accès</h2>
            <p>
                Nous reconnaissons votre droit d'accéder à vos informations personnelles et de les rectifier si nécessaire. Pour exercer ce droit, veuillez contacter notre responsable de la protection des renseignements personnels, Vincent Royer.
            </p>

            <h2>Sécurité</h2>
            <p>
                Les données personnelles que nous collectons sont stockées dans un environnement sécurisé. Nous utilisons le protocole SSL pour garantir la confidentialité de vos transactions. Toutefois, il est important de noter qu'aucun mécanisme ne peut garantir une sécurité totale sur Internet.
            </p>

            <h2>Législation du Québec</h2>
            <p>
                Nous nous engageons à respecter les dispositions légales en vigueur au Québec.
            </p>
             <Footer lastBlogPost={lastBlogPost} />
        </div>

    );
};

export default PolitiqueDeConfidentialite;
