import React from 'react'
import './ask.css'

const ask = () => {
    return (
        <div className="ask-wrapper">
            <div className="card">
                <div className="card__text-wrapper">
                    <div className="card__title">Allez. Dérange-nous! On est prêts</div>
                    <div className="card__description">On t'écoute. On discute. On analyse tes enjeux et tes défis. On te propose <b>LA solution</b>. <br /><br /><span className='concretiser'> Dis-nous ce que tu veux concrétiser.</span></div>
                </div>
                <div className="card__link"><a href="/Contactpage">Demander une soumission</a></div>
            </div>
            <div className="card">
                <div className="card__title">Demande nous ce que tu veux</div>
                <div className="card__description">C'est quoi ça, une identité de marque? Votre <i>workflow</i> lui, fonctionne comment? Les délais de livraison ressemblent à quoi?<br/> <br></br> <span className='concretiser'> Vous pouvez demander ce que vous voulez.</span></div>
                <div className="card__link"><a href="/Contactpage"> Nous écrire directement</a></div>
            </div>
            <div className="card">
            <div className="card__title">Notre horaire</div>
                <div className="card__description">Lundi : 8h00 à 17h00 <br/>Mardi : 8h00 à 17h00 <br/> Mercredi : 8h00 à 17h00 <br/>Jeudi : 8h00 à 17h00 <br/> Vendredi : 8h00 à 12h00 <br/>Samedi : Fermé <br/> Dimanche : Fermé</div>
                <div className="card__link"> hello@swayedoriginal.com</div>
            </div>
        </div>
    )
}

export default ask
