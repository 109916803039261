import VOL1 from '../../assets/bavards/vol1.webp';
import VOL2 from '../../assets/bavards/vol2.webp';
import VOL3 from '../../assets/bavards/vol3.webp';
import VOL4 from '../../assets/bavards/vol4.webp';
import VOL5 from '../../assets/bavards/vol5.webp';
import VOL6 from '../../assets/bavards/vol6.webp';

const blogPosts = [
  {
    title: 'une aventure creative dans le monde de demain',
    category: 'Web',
    author: 'Vincent Royer',
    date: '24 août 2023',
    volume: 'Volume 6',
    imageSrc: VOL6,
    link: '/vol6',
  },
  {
    title: 'au–dela des clics – les essentiels en web',
    category: 'Web',
    author: 'Vincent Royer',
    date: '17 mai 2023',
    volume: 'Volume 5',
    imageSrc: VOL5,
    link: '/vol5',
  },
  {
    title: 'maitriser les nuances de couleurs',
    category: 'Graphisme',
    author: 'Vincent Royer',
    date: '11 avril 2023',
    volume: 'Volume 4',
    imageSrc: VOL4,
    link: '/vol4',
  },
  {
    title: 'raviver la flamme en renouant avec l\'imagination de votre enfance',
    category: 'Psychologie',
    author: 'Vincent Royer',
    date: '16 mars 2023',
    volume: 'Volume 3',
    imageSrc: VOL3,
    link: '/vol3',
  },
  {
    title: 'quelle police d\'ecriture choisir pour sa marque',
    category: 'Typographie',
    author: 'Vincent Royer',
    date: '09 mars 2023',
    volume: 'Volume 2',
    imageSrc: VOL2,
    link: '/vol2',
  },
  {
    title: 'comment creer sa charte graphique',
    category: 'Identité',
    author: 'Vincent Royer',
    date: '24 février 2023',
    volume: 'Volume 1',
    imageSrc: VOL1,
    link: '/vol1',
  },
  // Add more blog posts here...
];

export default blogPosts;