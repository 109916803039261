import React from 'react'
import './services.css'
import Design from '../../assets/design.webp'

const Services = () => {
  return (

    <section id="services">
      <h2>Expertise</h2>
      <div className="services">
        <div className="services__item">
          <h3>Identité de marque</h3>
          <p>
            <ul>
              <li>analyse de marque & étude de marché</li>
              <li>couleurs | typographies | styles</li>
              <li>guide de normes graphiques</li>
              <li>modèles de documents</li>
              <li>logos & éléments visuels</li>
              <li>directives de marque</li>
            </ul>
          </p>
        </div>
        <div className="services__item">
          <h3>Design graphique</h3>
          <p>
            <ul>
              <li>sites & interfaces (ui/ux)</li>
              <li>supports publicitaire</li>
              <li>packaging & étiquetage</li>
              <li>réseaux sociaux</li>
              <li>supports de marketing</li>
              <li>livres & magazines</li>
            </ul>
          </p>
        </div>
        <div className="services__item">

          <h3>Sites Web</h3>
          <p>
            <ul>
              <li>conception et développement</li>
              <li>responsive design</li>
              <li>analyse du trafic</li>
              <li>commerce électronique</li>
              <li>traduction</li>
              <li>formation</li>
            </ul>
          </p>
        </div>
        <div className="services__item">

          <h3>Optimisation SEO</h3>
          <p>
            <ul>
              <li>audit seo initial</li>
              <li>balises méta</li>
              <li>vitesse de chargement</li>
              <li>suivi et analyse des performances</li>
              <li>optimisation de la structure</li>
              <li>création de contenu seo</li>
            </ul>
          </p>
        </div>

      </div>
    </section >


  )
}

export default Services