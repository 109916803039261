import React from 'react'
import { BsFacebook } from 'react-icons/bs'
import { BsInstagram } from 'react-icons/bs'
import { BsSpotify } from 'react-icons/bs'

function HeaderSocials() {
  return (
    <div className='header__socials'>
      <a href="https://www.facebook.com/profile.php?id=100083720064113" rel="noopener noreferrer" target="_blank" class="social-menu"><BsFacebook /></a>
      <a href="https://www.instagram.com/swayedoriginal/" rel="noopener noreferrer" target="_blank" className='social-menu'><BsInstagram /></a>
      <a href="https://open.spotify.com/user/21mset5dtora36e2d2sd663xi?si=2565903794ee4a85" rel="noopener noreferrer" target="_blank" className='social-menu'><BsSpotify /></a>
    </div>
  )
}

export default HeaderSocials